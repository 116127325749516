

































































import { defineComponent, ref, reactive } from '@vue/composition-api';
import { useUpdateParticipantsScoreMutation } from '@/generated/graphql';
import SnackBar from '@/components/atoms/Snackbars.vue';
import snackBarComposition from '@/composable/snackbar';
import LoaderSpinMini from '@/components/atoms/LoaderSpinMini.vue';

export default defineComponent({
  name: 'EditGameForm',

  components: {
    SnackBar,
    LoaderSpinMini,
  },
  props: {
    id: {
      type: String,
    },
    participantsScores: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    const dialog = ref(false);
    const loading = ref(false);

    const {
      timeout, snackbar, message, snackbarHandler,
    } = snackBarComposition();

    const { mutate: updateScore } = useUpdateParticipantsScoreMutation(() => ({}));

    const updateData = reactive({
      participant_id: '',
      score: 0,
    });

    function editWrapper() {
      loading.value = true;
      console.log('Data', updateData);
      updateScore(updateData).then((result) => {
        if (result.data.update_participants.affected_rows) {
          updateData.participant_id = '';
          updateData.score = 0;
          snackbarHandler('Update Successfully!');
        } else {
          snackbarHandler('Error! Try again later.');
        }
        loading.value = false;
        dialog.value = false;
      }).catch((err) => {
        loading.value = false;
        console.error(err);
        snackbarHandler('Error! Try again later!');
      });
    }

    return {
      dialog,
      loading,
      editWrapper,
      updateData,
      timeout,
      message,
      snackbar,
    };
  },
});
