

































import { defineComponent } from '@vue/composition-api';
import AdminArtifact from '@/components/molecules/AdminArtifact.vue';

export default defineComponent({
  name: 'ArtifactCard',
  components: {
    AdminArtifact,
  },
  props: {
    team: {
      type: Object,
      default: () => ({
        name: 'TEAM1',
        id: 'c15a5929-0564-4e24-8811-930ab511408c',
        participants: [
          {
            picometer: true,
            viewfinder: true,
            id: '81f044f0-e926-436c-a1ed-8bc45d5c49a4',
            name: 'Participant1',
          },
          {
            picometer: true,
            viewfinder: true,
            id: 'a9927000-3c5e-4536-8bfa-b9992f852deb',
            name: 'Participant2',
          },
        ],
      }),
    },
    type: {
      type: String,
    },
  },
});
