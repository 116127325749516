




































































import { defineComponent, ref, watch } from '@vue/composition-api';
import GameCard from '@/components/organisms/GameCard.vue';
import TeamCard from '@/components/organisms/TeamCard.vue';
import ArtifactCard from '@/components/organisms/ArtifactCard.vue';
import AdminEmergencyMeetingCard from '@/components/organisms/AdminEmergencyMeetingCard.vue';
import ContestLeaderboard from '@/components/organisms/ContestLeaderboard.vue';
import LoaderSpin from '@/components/atoms/LoaderSpin.vue';
import {
  useSubscibeToAllGamesSubscription, useGetParticipantsScoreSubscription, useGetParticipantsPicometerDetailsSubscription, useGetParticipantsViewfinderDetailsSubscription,
  useGetEmergencyMeetingStatusSubscription, useContestSubmissionLiveResultSubscription,
} from '@/generated/graphql';
import ContestControlButton from '@/components/atoms/ContestControlButton.vue';

export default defineComponent({
  name: 'Admin',

  components: {
    GameCard,
    TeamCard,
    LoaderSpin,
    ContestControlButton,
    ArtifactCard,
    AdminEmergencyMeetingCard,
    ContestLeaderboard,
  },

  setup() {
    const { result, loading, error } = useSubscibeToAllGamesSubscription();
    const games = ref();
    const mode = ref(process.env.NODE_ENV);
    watch(
      result,
      (data) => {
        games.value = data.games;
      },
    );
    const { result: result1, loading: loading1, error: error1 } = useGetParticipantsScoreSubscription();
    const teams = ref();
    watch(
      result1,
      (data) => {
        teams.value = data.teams;
      },
    );
    const { result: result2, loading: loading2, error: error2 } = useGetParticipantsPicometerDetailsSubscription();
    const picometer = ref();
    watch(
      result2,
      (data) => {
        picometer.value = data.teams;
      },
    );
    const { result: result3, loading: loading3, error: error3 } = useGetParticipantsViewfinderDetailsSubscription();
    const viewfinder = ref();
    watch(
      result3,
      (data) => {
        viewfinder.value = data.teams;
      },
    );
    const { result: result4, loading: loading4, error: error4 } = useGetEmergencyMeetingStatusSubscription();
    const emergencyMeetingStatus = ref();
    watch(
      result4,
      (data) => {
        emergencyMeetingStatus.value = data.teams;
      },
    );

    const { result: result5, loading: loading5, error: error5 } = useContestSubmissionLiveResultSubscription();
    const contestLeaderboard = ref();
    watch(
      result5,
      (data) => {
        contestLeaderboard.value = data.participants;
      },
    );

    return {
      games,
      picometer,
      viewfinder,
      emergencyMeetingStatus,
      contestLeaderboard,
      teams,
      loading,
      loading1,
      error,
      error1,
      mode,
    };
  },
});
