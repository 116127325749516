













































































import { defineComponent } from '@vue/composition-api';
import { useGetControlStateQuery, useUpdateContestStateMutation, useUpdateLeadershipboardStateMutation } from '@/generated/graphql';
import { useResult } from '@vue/apollo-composable';
import LoaderSpin from '@/components/atoms/LoaderSpin.vue';

export default defineComponent({
  components: {
    LoaderSpin,
  },
  setup() {
    const {
      result, loading, error, refetch,
    } = useGetControlStateQuery({
      notifyOnNetworkStatusChange: true,
    });
    const stateControl = useResult(result, [
      {
        vote: false,
        submission: false,
        leaderboard: true,
      },
    ], (data) => data.control[0]);

    const { mutate: updateContestState } = useUpdateContestStateMutation({});

    function contestControl(submission: boolean, vote: boolean) {
      updateContestState({
        submission,
        vote,
      }).then((res) => {
        if (res.data.update_control.affected_rows) {
          refetch();
        }
      });
      console.log('Submission', submission, 'vote', vote);
    }

    const { mutate: updateLeadershipboardState } = useUpdateLeadershipboardStateMutation({});
    function leaderboardControl(leaderboard: boolean) {
      updateLeadershipboardState({
        leaderboard,
      }).then((res) => {
        if (res.data.update_control.affected_rows) {
          refetch();
        }
      });
      console.log('leaderboard', leaderboard);
    }

    return {
      stateControl,
      loading,
      error,
      contestControl,
      leaderboardControl,
    };
  },
});
