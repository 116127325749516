



































import { defineComponent } from '@vue/composition-api';
import EditGameForm from '@/components/molecules/EditGameForm.vue';

export default defineComponent({
  name: 'GameCard',
  components: {
    EditGameForm,
  },
  props: {
    game: {
      type: Object,
      default: () => ({
        id: '3b48e7d9-50ef-41c6-a0be-d1e72975e17c',
        game_name: 'Game',
        teams_scores: [{
          score: 3000,
          team_id: 'c15a5929-0564-4e24-8811-930ab511408c',
          team: {
            name: 'TEAM1',
          },
        }],
      }),
    },
  },
});
