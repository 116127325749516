




































import { defineComponent } from '@vue/composition-api';
import EditEmergencyMeetingForm from '@/components/molecules/EditEmergencyMeetingForm.vue';

export default defineComponent({
  name: 'AdminEmergencyMeetingCard',
  components: {
    EditEmergencyMeetingForm,
  },
  props: {
    team: {
      type: Object,
      default: () => ({
        name: 'Rainbow Team',
        emergency_meeting: false,
        number: 10,
        participants: [{
          name: 'Ah Beng',
          imposter_vote_count: 0,
        }, {
          name: 'Roger',
          imposter_vote_count: 0,
        }],
      }),
    },
  },
  setup() {
    function startStopEmergencyMeetingWrapper() {
      const a = 1;
    }

    return {
      startStopEmergencyMeetingWrapper,
    };
  },
});
