

































































import { defineComponent, ref, reactive } from '@vue/composition-api';
import { useUpdateGameScoreMutation } from '@/generated/graphql';
import SnackBar from '@/components/atoms/Snackbars.vue';
import snackBarComposition from '@/composable/snackbar';
import LoaderSpinMini from '@/components/atoms/LoaderSpinMini.vue';

export default defineComponent({
  name: 'EditGameForm',

  components: {
    SnackBar,
    LoaderSpinMini,
  },
  props: {
    id: {
      type: String,
    },
    teamsScores: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const scoreOptions = ref([0, 250, 500, 750, 1000]);
    const dialog = ref(false);
    const loading = ref(false);

    const {
      timeout, snackbar, message, snackbarHandler,
    } = snackBarComposition();

    const { mutate: updateScore } = useUpdateGameScoreMutation(() => ({}));

    const updateData = reactive({
      game_id: props.id || '',
      team_id: '',
      score: 0,
    });

    function editWrapper() {
      loading.value = true;
      console.log('Data', updateData);
      updateScore(updateData).then((result) => {
        if (result.data.update_teams_scores.affected_rows) {
          updateData.team_id = '';
          updateData.score = 0;
          snackbarHandler('Update Successfully!');
        } else {
          snackbarHandler('Error! Try again later.');
        }
        loading.value = false;
        dialog.value = false;
      }).catch((err) => {
        loading.value = false;
        console.error(err);
        snackbarHandler('Error! Try again later!');
      });
    }

    return {
      dialog,
      loading,
      editWrapper,
      scoreOptions,
      updateData,
      timeout,
      message,
      snackbar,
    };
  },
});
