




































































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ContestLeaderboard',

  components: {
  },

  data() {
    return {
      headers: [
        {
          text: 'Participants',
          align: 'start',
          value: 'name',
        },
        { text: 'Score', value: 'contest_submission.vote_count' },
      ],
    };
  },

  props: {
    participants: {
      type: Array,
      default: () => [{
        name: 'Roger',
        picture_url: 'https://picsum.photos/50',
        score: '20',
      },
      {
        name: 'HUMAN2077',
        picture_url: 'https://picsum.photos/50',
        score: '19',
      },
      {
        name: 'Tan Ah Beng',
        picture_url: 'https://picsum.photos/50',
        score: '43',
      },
      ],
    },
  },
});
