



















































































import { defineComponent, ref, reactive } from '@vue/composition-api';
import { useUpdateParticipantsPicometerMutation, useUpdateParticipantsViewfinderMutation } from '@/generated/graphql';
import SnackBar from '@/components/atoms/Snackbars.vue';
import snackBarComposition from '@/composable/snackbar';
import LoaderSpinMini from '@/components/atoms/LoaderSpinMini.vue';

export default defineComponent({
  name: 'AdminArtifact',

  components: {
    SnackBar,
    LoaderSpinMini,
  },
  props: {
    participants: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
    },
  },

  setup(props) {
    const dialog = ref(false);
    const loading = ref(false);

    const {
      timeout, snackbar, message, snackbarHandler,
    } = snackBarComposition();

    const { mutate: updateHint } = useUpdateParticipantsPicometerMutation(() => ({}));
    const { mutate: updateHint1 } = useUpdateParticipantsViewfinderMutation(() => ({}));

    const updateData = reactive({
      participant_id: '',
      picometer_hint: '',
    });
    const updateData1 = reactive({
      participant_id: '',
      viewfinder_hint: '',
    });

    function editWrapper() {
      loading.value = true;
      console.log(updateData, updateData1);
      if (props.type === 'picometer') {
        updateHint(updateData).then((result) => {
          if (result.data.update_participants.affected_rows) {
            updateData.participant_id = '';
            updateData.picometer_hint = '';
            snackbarHandler('Update Successfully!');
          } else {
            snackbarHandler('Error! Try again later.');
          }
          loading.value = false;
          dialog.value = false;
        }).catch((err) => {
          loading.value = false;
          console.error(err);
          snackbarHandler('Error! Try again later!');
        });
      } else {
        console.log(updateData1);
        updateHint1(updateData1).then((result) => {
          if (result.data.update_participants.affected_rows) {
            updateData1.participant_id = '';
            updateData1.viewfinder_hint = '';
            snackbarHandler('Update Successfully!');
          } else {
            snackbarHandler('Error! Try again later.');
          }
          loading.value = false;
          dialog.value = false;
        }).catch((err) => {
          loading.value = false;
          console.error(err);
          snackbarHandler('Error! Try again later!');
        });
      }
    }

    return {
      dialog,
      loading,
      editWrapper,
      updateData,
      updateData1,
      timeout,
      message,
      snackbar,
    };
  },
});
