



































import { defineComponent } from '@vue/composition-api';
import EditTeamForm from '@/components/molecules/EditTeamForm.vue';

export default defineComponent({
  name: 'TeamCard',
  components: {
    EditTeamForm,
  },
  props: {
    team: {
      type: Object,
      default: () => ({
        name: 'TEAM1',
        id: 'c15a5929-0564-4e24-8811-930ab511408c',
        participants: [
          {
            name: 'Official Account Crewmate 1',
            score: 618,
            id: 'f4c18794-b85f-4bca-a121-c8e0d1df10fd',
          },
          {
            name: 'team1_crewmate1 ...',
            score: 0,
            id: 'c743b409-35d0-4d28-b098-1ff2599bbf17',
          },
        ],
      }),
    },
  },
});
